import { AZURE_AUTHORITY, POST_LOGOUT_REDIRECT_URI, AZURE_CLIENT_ID } from '../constants/config';

import { UserAgentApplication, Logger, LogLevel } from 'msal';

export const requiresInteraction = (
  errorMessage: Record<string, unknown | any>
): boolean | Record<string, unknown> => {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }

  return (
    errorMessage.indexOf('consent_required') > -1 ||
    errorMessage.indexOf('interaction_required') > -1 ||
    errorMessage.indexOf('login_required') > -1
  );
};

// export const fetchMsGraph = async (url, accessToken) => {
//   const response = await fetch(url, {
//     headers: {
//       Authorization: `Bearer ${accessToken}`,
//     },
//   });

//   return response.json();
// };

export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ') > -1;
  const msie11 = ua.indexOf('Trident/') > -1;

  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  // const isEdge = ua.indexOf("Edge/") > -1;

  return msie || msie11;
};

export const GRAPH_SCOPES = {
  OPENID: 'openid',
  PROFILE: 'profile',
  USER_READ: 'User.Read',
  MAIL_READ: 'Mail.Read',
};

export const GRAPH_ENDPOINTS = {
  ME: 'https://graph.microsoft.com/v1.0/me',
  MAIL: 'https://graph.microsoft.com/v1.0/me/messages',
};

export const GRAPH_REQUESTS = {
  LOGIN: {
    scopes: [
      //"api://415c4506-6fed-4324-9e1e-251435183d0c/access_as_user"
      'api://fe0a14f8-4cc4-450f-9cfa-247d665ac853/access_as_user',

      // GRAPH_SCOPES.OPENID,
      // GRAPH_SCOPES.PROFILE,
      // GRAPH_SCOPES.USER_READ
    ],
  },
  EMAIL: {
    scopes: [GRAPH_SCOPES.MAIL_READ],
  },
};

export const msalApp = new UserAgentApplication({
  auth: {
    clientId: AZURE_CLIENT_ID,
    authority: AZURE_AUTHORITY,
    validateAuthority: true,
    postLogoutRedirectUri: POST_LOGOUT_REDIRECT_URI,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: isIE(),
  },
  system: {
    navigateFrameWait: 500,
    logger: new Logger(
      (logLevel, message) => {
        console.log(message);
      },
      {
        level: LogLevel.Verbose,
        piiLoggingEnabled: true,
      }
    ),
    telemetry: {
      applicationName: 'react-sample-app',
      applicationVersion: '1.0.0',
      telemetryEmitter: (events) => {
        console.log('Telemetry Events:', events);
      },
    },
  },
});
