export const MODULE_HOMEPAGE = process.env?.REACT_APP_MODULE_HOMEPAGE || 'homepage';
export const API_PREFIX =
  process.env?.REACT_APP_API_PREFIX || 'https://empacknowledgment.azurewebsites.net/api/';

export const AZURE_CLIENT_ID = process.env?.REACT_APP_AZURE_CLIENT_ID || '';
export const AZURE_AUTHORITY = process.env?.REACT_APP_AZURE_AUTHORITY || '';
export const POST_LOGOUT_REDIRECT_URI = process.env?.REACT_APP_POST_LOGOUT_REDIRECT_URI || '';
export const TYPING_TIMEOUT =
  process.env.REACT_APP_TYPING_TIMEOUT === undefined
    ? 400
    : parseInt(process.env.REACT_APP_TYPING_TIMEOUT);
export const TABLE_PAGINATION = (process.env.REACT_APP_TABLE_PAGINATION || '20,50,100')
  .split(',')
  .map((x) => parseInt(x));
