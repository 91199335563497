import React, { Component } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from '@material-ui/core';

class AlertDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: this.props.open,
      title: this.props.title,
      message: this.props.message,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.open !== this.props.open ||
      prevProps.title !== this.props.title ||
      prevProps.message !== this.props.message
    ) {
      this.setState({
        open: this.props.open,
        title: this.props.title,
        message: this.props.message,
      });
    }
  }

  render() {
    return (
      <Dialog maxWidth="sm" fullWidth={true} open={this.state.open}>
        <DialogTitle>{this.props.title}</DialogTitle>

        <DialogContent dividers>
          <DialogContentText>{this.state.message}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              this.setState({ open: false });
              this.props.closeHandler();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default AlertDialog;
