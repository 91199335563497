import { Box } from '@material-ui/core';
import { BASE_PAGE, DASHBOARD } from 'constants/paths';
import { routes } from 'constants/routes';
import { Provider as UserContext } from 'context/UserContext/UserContext';
import React, { Component, ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthProvider from '../ADLogin/AuthProvider';
import AppLogin from './AppLogin';
import { Footer } from './Footer';
import Header from './Header';

interface ApplicationProps {
  token: string;
  account: any;
  onSignIn: () => void;
  onSignOut: () => void;
}

class Application extends Component<ApplicationProps, {}> {
  render(): ReactElement {
    if (!this.props.account || !this.props.token) {
      return (
        <>
          <Header />
          <AppLogin loginHandler={this.props.onSignIn} />
          <Footer />
        </>
      );
    } else {
      return (
        <UserContext authToken={this.props.token}>
          <Header
            account={this.props.account}
            authToken={this.props.token}
            logoutHandler={this.props.onSignOut}
          />

          <Box m={3}></Box>
          <Switch>
            {routes.map((route: any) => (
              <Route
                exact={route.exact}
                key={route.path}
                path={route.path}
                render={(props) => (
                  <route.component
                    {...props}
                    routes={route.routes}
                    authToken={this.props.token}
                    logoutHandler={this.props.onSignOut}
                  />
                )}
              />
            ))}
            <Redirect from={BASE_PAGE} to={DASHBOARD} />
          </Switch>
          <Footer />
        </UserContext>
      );
    }
  }
}

export default AuthProvider(Application);
