import React, { Component } from 'react';
import { msalApp, requiresInteraction, isIE, GRAPH_REQUESTS } from './auth-utils';
import { withRouter } from 'react-router-dom';
import { DASHBOARD, LOGIN_PAGE } from 'constants/paths';

// If you support IE, our recommendation is that you sign-in using Redirect APIs
const useRedirectFlow = isIE();

export default (C) =>
  withRouter(
    class AuthProvider extends Component {
      constructor(props) {
        super(props);

        this.state = {
          account: null,
          token: null,
          error: null,
          emailMessages: null,
          graphProfile: null,
        };
      }

      async acquireToken(request, redirect) {
        return msalApp.acquireTokenSilent(request).catch((error) => {
          // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
          // due to consent or interaction required ONLY
          if (requiresInteraction(error.errorCode)) {
            return redirect
              ? msalApp.acquireTokenRedirect(request)
              : msalApp.acquireTokenPopup(request);
          } else {
            console.error('Non-interactive error:', error.errorCode);
          }
        });
      }

      async retrieveToken() {
        const tokenResponse = await this.acquireToken(GRAPH_REQUESTS.LOGIN).catch((error) => {
          this.setState({
            error: error.message,
          });
        });

        if (tokenResponse) {
          this.setState({
            token: tokenResponse.accessToken,
          });
        }

        return tokenResponse;
      }

      async onSignIn(redirect) {
        if (redirect) {
          return msalApp.loginRedirect(GRAPH_REQUESTS.LOGIN);
        }

        const loginResponse = await msalApp.loginPopup(GRAPH_REQUESTS.LOGIN).catch((error) => {
          this.setState({
            error: error.message,
          });
        });

        if (loginResponse) {
          this.setState({
            account: loginResponse.account,
            error: null,
          });

          const tokenResponse = await this.retrieveToken();

          if (tokenResponse) {
            const { history } = this.props;
            const { afterLoginRedirectUri } = this.state;
            if (afterLoginRedirectUri) {
              history.replace(afterLoginRedirectUri);
            } else {
              history.replace(DASHBOARD);
            }
          }
        }
      }

      onSignOut() {
        msalApp.logout();
      }

      setAfterLogin = (url) => {
        this.setState({ afterLoginRedirectUri: url });
      };

      setAcount() {
        console.log('setAccount missing');
      }

      async componentDidMount() {
        msalApp.handleRedirectCallback((error) => {
          if (error) {
            const errorMessage = error.errorMessage
              ? error.errorMessage
              : 'Unable to acquire access token.';
            // setState works as long as navigateToLoginRequestUrl: false
            this.setState({
              error: errorMessage,
            });
          }
        });

        const currentAccount = msalApp.getAccount();

        await this.retrieveToken();

        const { token } = this.state;
        const { history, location } = this.props;

        const loggedin = !!token && !!currentAccount;

        if (!loggedin && !location.pathname?.includes('/login')) {
          this.setAfterLogin(history.location.pathname);
          history.replace(LOGIN_PAGE);
        }

        if (currentAccount) {
          this.setAcount({ variables: { account: currentAccount } });
        }

        if (loggedin && location.pathname?.includes('/login')) {
          history.replace(DASHBOARD);
        }

        this.setState({
          account: currentAccount,
        });
      }

      render() {
        return (
          <C
            {...this.props}
            account={this.state.account}
            token={this.state.token}
            emailMessages={this.state.emailMessages}
            error={this.state.error}
            graphProfile={this.state.graphProfile}
            onSignIn={() => this.onSignIn(useRedirectFlow)}
            onSignOut={() => this.onSignOut()}
          />
        );
      }
    }
  );
