import React, { Component } from 'react';
import { Typography, TextField, Grid } from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';

class EasyAutoComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: this.props.items,
      selected: this.props.selected,

      name: this.props.name,
      label: this.props.label,
      optionsLabel: this.props.optionsLabel,
      optionsLabel2: this.props.optionsLabel2,
      textFieldStyle: this.props.textFieldStyle || 'standard',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.items !== this.props.items ||
      prevProps.selected !== this.props.selected ||
      prevProps.name !== this.props.name ||
      prevProps.label !== this.props.label ||
      prevProps.textFieldStyle !== this.props.textFieldStyle ||
      prevProps.optionsLabel !== this.props.optionsLabel ||
      prevProps.optionsLabel2 !== this.props.optionsLabel2
    ) {
      this.setState({
        items: this.props.items,
        selected: this.props.selected,
        name: this.props.name,
        label: this.props.label,
        textFieldStyle: this.props.textFieldStyle,
        optionsLabel: this.props.optionsLabel,
        optionsLabel2: this.props.optionsLabel2,
      });
    }
  }

  componentDidMount() {}

  getOptionLabel = (option) => {
    let first = this.state.optionsLabel;
    let second = this.state.optionsLabel2;

    if (!second) return option[first];
    else return option[first] + ' ' + option[second];
  };

  getRenderWithLeftLabel = () => {
    return (
      <Grid container spacing={1} justify="center" alignContent="center" alignItems="center">
        <Grid item xs={4}>
          <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
            {this.state.label}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Autocomplete
            disableClearable
            fullWidth
            value={this.state.selected}
            onChange={(event, value) => {
              this.setState({ selected: value });

              this.props.selectedChanged(value);
            }}
            autoHighlight
            // options={(this.state.readOnly === true || this.getReadOnly("project")) ? [this.state.selectedProject] : this.state.projects}

            options={this.state.items}
            getOptionLabel={this.props.selectedOptionLabel}
            name={'autoComplete-' + this.state.name}
            key={'autocomplete-' + this.state.name + '-key'}
            renderOption={(option) => (
              <Typography variant="body2" component="span">
                {this.getOptionLabel(option)}
              </Typography>
            )}
            renderInput={(params) => (
              <TextField
                name={'textField-' + this.state.name}
                key={'textfield' + this.state.name + '-key'}
                margin="dense"
                fullWidth
                {...params}
                //label={this.state.label}

                variant={this.state.textFieldStyle}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill

                  // readOnly: (this.state.readOnly === true || this.getReadOnly("project"))
                }}
              />
            )}
          ></Autocomplete>
        </Grid>
      </Grid>
    );
  };

  getRenderNormal = () => {
    return (
      <Autocomplete
        disableClearable
        fullWidth
        value={this.state.selected}
        onChange={(event, value) => {
          this.setState({ selected: value });

          this.props.selectedChanged(value);
        }}
        autoHighlight
        // options={(this.state.readOnly === true || this.getReadOnly("project")) ? [this.state.selectedProject] : this.state.projects}

        options={this.state.items}
        getOptionLabel={this.props.selectedOptionLabel}
        name={'autoComplete-' + this.state.name}
        key={'autocomplete-' + this.state.name + '-key'}
        renderOption={(option) => (
          <Typography variant="body2" component="span">
            {this.getOptionLabel(option)}
          </Typography>
        )}
        renderInput={(params) => (
          <TextField
            name={'textField-' + this.state.name}
            key={'textfield' + this.state.name + '-key'}
            margin="dense"
            fullWidth
            {...params}
            label={this.state.label}
            variant={this.state.textFieldStyle}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill

              // readOnly: (this.state.readOnly === true || this.getReadOnly("project"))
            }}
            InputLabelProps={{
              style: { fontWeight: 'bold' },
            }}
          />
        )}
      ></Autocomplete>
    );
  };

  getRenderComponent = () => {
    return this.getRenderNormal();
  };

  render() {
    return this.getRenderComponent();
  }
}

export default EasyAutoComplete;
