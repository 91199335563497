import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import MaterialTable, { MTableBodyRow } from 'material-table';

import {
  Paper,
  Container,
  Box,
  Grid,
  Button,
  TextField,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import LoadingOverlay from 'react-loading-overlay';

import AlertDialog from '../Misc/AlertDialog';

import {
  ApiCallGetActiveEmployee,
  ApiCallSubmitAcknowledgement,
  ApiCallGetAcknowledgedEmployees,
} from '../../Utils/ApiCalls';
import EmployeeInfo from './EmployeeInfo';
import EmpAckCompletionSetDate from './EmpAckCompletionSetDate';
// import CompletionDatesDisplay from './CompletionDatesDisplay';
import s from './style.module.css';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const HomePage = (props) => {
  const [data, setData] = useState({
    empNo: '',
    empName: '',
    signature: '',
    date: '',
    comments: '',
    submitEnabled: false,
    manager: false,
    executive: false,
    confirmedBeforeSubmitting: false,
    managerDivisions: [],
  });
  let {
    comments,
    empNo,
    empName,
    signature,
    date,
    submitEnabled,
    manager,
    // executive,
    confirmedBeforeSubmitting,
    managerDivisions,
  } = data;

  const [managerEmployees, setManagerEmployees] = useState([]);
  const [employeesListColumns, setEmployeesListColumns] = useState([]);

  const [loading, setLoading] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Info');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);

  const [, screenHeight] = useWindowSize();

  const [employeeInfoData, setEmployeeInfoData] = useState({});
  const [employeeInfoOpen, setEmployeeInfoOpen] = useState(false);
  const [empAckSetDateOpen, setEmpAckSetDateOpen] = useState(false);
  // const [completionDatesViewOpen, setCompletionDatesViewOpen] = useState(false);

  //#region  Api Calls Responses

  const getActiveEmployeeResponse = useCallback(
    (invalid, responseData) => {
      setLoading(false);

      if (invalid) {
        setData((prevData) => {
          let d = prevData;
          d.submitEnabled = false;
          return { ...prevData, d };
        });

        setAlertTitle('Error');
        setAlertMessage('Sorry, you are not authorized to use this form.');
        setAlertOpen(true);
      } else {
        setData((prevData) => {
          let d = prevData;
          d.empNo = responseData.code;
          d.empName = responseData.name;
          d.signature = responseData.email;
          d.date = responseData.date;
          d.comments = responseData.comments;
          d.submitEnabled = responseData.submitEnabled || responseData.hasPendingAcknowledgement;
          d.manager = responseData.manager;
          d.executive = responseData.executive;
          d.managerDivisions = responseData.managerDivisions;
          d.hasPendingAcknowledgement = responseData.hasPendingAcknowledgement;
          return { ...prevData, d };
        });

        if (responseData.manager === true) {
          setLoading(true);
          ApiCallGetAcknowledgedEmployees(props.authToken, getAcknowledgedEmployeesResponse);
        }
      }
    },
    [props.authToken]
  );

  const getAcknowledgedEmployeesResponse = (invalid, data) => {
    setLoading(false);

    setManagerEmployees(data);
  };

  const submitFormResponse = (invalid, data) => {
    setLoading(false);

    setAlertTitle('Success');
    setAlertMessage('Your acknowledgment is submitted.');
    setAlertOpen(true);

    setd('submitEnabled', false);
  };
  //#endregion

  useEffect(() => {
    ApiCallGetActiveEmployee(props.authToken, getActiveEmployeeResponse);
    setLoading(true);

    setListColumns();

    return function cleanup() {};
  }, [props.authToken, getActiveEmployeeResponse]);

  const setd = (elm, val) => {
    let d = data; //JSON.parse( JSON.stringify(data));
    d[elm] = val;

    setData({ ...data, d });
  };

  const submitForm = () => {
    if (!confirmedBeforeSubmitting) {
      setAlertTitle('Error');
      setAlertMessage(
        'You must click on a confirmation checkbox before submitting your acknowledgment.'
      );
      setAlertOpen(true);

      return;
    }

    setLoading(true);

    let d = {
      comments: comments,
    };

    ApiCallSubmitAcknowledgement(props.authToken, d, submitFormResponse);
  };

  const setListColumns = () => {
    let columns = [];

    columns.push({
      title: 'Code',
      field: 'code',
      width: '40px',
      grouping: false,
    });

    columns.push({
      title: 'Name',
      field: 'name',
      width: '170px',
      grouping: false,

      cellStyle: {
        maxWidth: '170px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    });

    columns.push({
      title: 'E-mail',
      field: 'email',
      width: '170px',
      grouping: false,
    });

    columns.push({
      title: 'Division',
      field: 'division',
      width: '170px',
      cellStyle: {
        maxWidth: '170px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    });

    columns.push({
      title: 'Acknowledged Date',
      field: 'date',
      width: '200px',
    });

    columns.push({
      title: 'User Comments',
      field: 'comments',
      grouping: false,

      cellStyle: {
        maxWidth: '10px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    });

    columns.push({
      title: 'Manager Comments',
      field: 'managerComments',
      grouping: false,

      cellStyle: {
        maxWidth: '10px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    });

    setEmployeesListColumns(columns);
  };

  let alternateRow = true;

  return (
    <Container maxWidth={manager ? 'xl' : 'md'}>
      <AlertDialog
        title={alertTitle}
        message={alertMessage}
        open={alertOpen}
        closeHandler={() => {
          setAlertOpen(false);
        }}
      />

      <EmployeeInfo
        data={employeeInfoData}
        open={employeeInfoOpen}
        closeHandler={() => {
          setEmployeeInfoOpen(false);
        }}
      />

      <EmpAckCompletionSetDate
        divisions={managerDivisions}
        authToken={props.authToken}
        open={empAckSetDateOpen}
        closeHandler={() => {
          setEmpAckSetDateOpen(false);
        }}
      />

      <LoadingOverlay active={loading} spinner text="Please wait...">
        <Paper elevation={3}>
          {(manager === false || data.hasPendingAcknowledgement) && (
            <Box p={3} style={{ textAlign: 'center' }}>
              <Box m={3}></Box>

              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField label="Employee No." fullWidth disabled value={empNo} />
                </Grid>
                <Grid item xs={7}>
                  <TextField label="Employee Name" fullWidth disabled value={empName} />
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={3}>
                  <TextField label="E-mail" fullWidth disabled value={signature} />
                </Grid>

                <Grid item xs={3}>
                  <TextField label="Date" fullWidth disabled value={date} />
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Comments (if any, limit to 1000 characters)"
                    multiline
                    rows={6}
                    fullWidth
                    value={comments}
                    onChange={(e) => {
                      setd('comments', e.target.value);
                    }}
                    disabled={!submitEnabled}
                    inputProps={{ maxLength: 1000 }}
                  />
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={12} style={{ textAlign: 'left' }}>
                  <Alert severity="info">
                    <AlertTitle>Note</AlertTitle>

                    <FormControlLabel
                      control={
                        <Checkbox
                          name="confirmBox"
                          disabled={!submitEnabled}
                          checked={confirmedBeforeSubmitting || !submitEnabled}
                          onChange={(e) => {
                            setd('confirmedBeforeSubmitting', e.target.checked);
                          }}
                        />
                      }
                      label="By clicking this box and submitting the acknowledgment the employee confirms that the review  was discussed with management&nbsp;
                                            and does not imply that the employee either agrees or disagrees with the employee assessment."
                    />

                    {/* The employee's signature confirms that the review was discussed with management
and does not imply that the employee either agrees or disagrees with the employee assessment.
 */}
                  </Alert>
                </Grid>
              </Grid>

              <Box m={3}></Box>
              <Divider />

              <Box m={3}></Box>

              <Grid container>
                <Grid item xs={5} />
                <Grid item xs={2}>
                  {submitEnabled && (
                    <Button
                      disabled={!confirmedBeforeSubmitting}
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={submitForm}
                    >
                      Submit
                    </Button>
                  )}
                  {!submitEnabled ? (
                    !manager ? (
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          props.logoutHandler();
                        }}
                      >
                        Logout
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          ApiCallGetActiveEmployee(props.authToken, getActiveEmployeeResponse);
                          setLoading(true);
                        }}
                      >
                        Continue
                      </Button>
                    )
                  ) : undefined}
                </Grid>
                <Grid item xs={5} />
              </Grid>

              <Box m={3}></Box>
            </Box>
          )}

          {manager === true && !data.hasPendingAcknowledgement && (
            <Box p={0} style={{ textAlign: 'center' }}>
              {/* <Box p={1}></Box> */}

              {/* {(managerDivisions.length > 0 || executive) && (
                <Box paddingLeft={1} style={{ textAlign: 'left' }}>
                  {managerDivisions.length > 0 && (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => {
                        setEmpAckSetDateOpen(true);
                      }}
                    >
                      Set Completion Date...
                    </Button>
                  )}
                  &nbsp;
                  {executive && (
                    <>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => {
                          setCompletionDatesViewOpen(true);
                        }}
                      >
                        View Completion Dates...
                      </Button>

                      <CompletionDatesDisplay
                        open={completionDatesViewOpen}
                        authToken={props.authToken}
                        closeHandler={() => {
                          setCompletionDatesViewOpen(false);
                        }}
                      />
                    </>
                  )}
                </Box>
              )} */}

              <Box m={1}></Box>

              <MaterialTable
                columns={employeesListColumns}
                data={managerEmployees}
                onRowClick={(evt, selectedRow) => {
                  setEmployeeInfoOpen(true);
                  setEmployeeInfoData(selectedRow);
                }}
                options={{
                  filtering: true,
                  toolbar: false,
                  //draggable: false, (must use for grouping to be enabled)
                  grouping: true,

                  headerStyle: { position: 'sticky', top: 0 },
                  maxBodyHeight: screenHeight - 350,
                  minBodyHeight: screenHeight - 350,
                  emptyRowsWhenPaging: false,
                  pageSize: 20,
                  pageSizeOptions: [10, 20, 30],
                  tableLayout: 'auto',
                  loadingType: 'linear',
                  debounceInterval: 450,
                  padding: 'dense',
                  rowStyle: (rowData) => {
                    alternateRow = !alternateRow;
                    return {
                      backgroundColor: !alternateRow ? '#F6F6F6' : '#FCFCFC',
                    };
                  },
                }}
                components={{
                  Row: (props) => {
                    return <MTableBodyRow {...props} className={s.dataTableRow} />;
                  },
                }}
              ></MaterialTable>

              <Box m={1}></Box>

              <Grid container>
                <Grid item xs={5} />
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      props.logoutHandler();
                    }}
                  >
                    Logout
                  </Button>
                </Grid>
                <Grid item xs={5} />
              </Grid>

              <Box p={1}></Box>
            </Box>
          )}
        </Paper>
      </LoadingOverlay>
    </Container>
  );
};

export default HomePage;
