import { AdminPage } from 'Components/AdminPage/AdminPage';
import React, { FC } from 'react';

export interface IAdminProps {
  authToken: string;
}

export const Admin: FC<IAdminProps> = ({ authToken, ...props }) => {
  return <AdminPage authToken={authToken} {...props} />;
};
