import { BASE_PAGE, LOGIN_PAGE, ADMIN_PAGE } from './paths';
import { Home } from 'Pages/Home/Home';
import { Login } from 'Pages/Login/Login';
import { Admin } from 'Pages/Admin/Admin';

export const routes = [
  {
    path: BASE_PAGE,
    exact: true,
    component: Home,
    private: true,
    guest: false,
  },
  {
    path: LOGIN_PAGE,
    exact: true,
    component: Login,
    guest: true,
  },
  {
    path: ADMIN_PAGE,
    exact: true,
    component: Admin,
    guest: true,
  },
];
