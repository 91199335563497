import { Theme } from 'Components/Theme/Theme';
import React, { FC } from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import Application from './Components/Application';

import './index.css';
import * as serviceWorker from './serviceWorker';

const App: FC<any> = () => (
  <Theme>
    <Router>
      <Application />
    </Router>
  </Theme>
);

render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
