import React, { Component } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Link,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import HomeIcon from '@material-ui/icons/Home';
import {
  ExitToApp as ExitToAppIcon,
  // Dashboard as DashboardIcon,
  Settings as AdminIcon,
} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';

import { grey } from '@material-ui/core/colors';
import { ADMIN_PAGE, DASHBOARD } from 'constants/paths';
import UserContext from 'context/UserContext/UserContext';

class Header extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      userDrawerOpen: false,

      sendMailOpen: false,
    };

    this.gotoHome = this.gotoHome.bind(this);
    this.logoutUser = this.logoutUser.bind(this);

    this.hideUserInfoDrawer = this.hideUserInfoDrawer.bind(this);
    this.displayUserInfoDrawer = this.displayUserInfoDrawer.bind(this);

    this.getUserInfoButton = this.getUserInfoButton.bind(this);
  }

  gotoHome() {
    this.setState({ userDrawerOpen: false });

    this.props.history.push(DASHBOARD);
  }

  logoutUser() {
    this.props.logoutHandler();
  }

  hideUserInfoDrawer() {
    this.setState({
      userDrawerOpen: false,
    });
  }

  displayUserInfoDrawer() {
    this.setState({
      userDrawerOpen: true,
    });
  }

  getUserInfoButton() {
    if (!this.props.account) return <></>;

    return (
      <div style={{ display: 'inline-block', marginTop: '8px', marginRight: '10px' }}>
        <Button endIcon={<MenuIcon />} variant="outlined" onClick={this.displayUserInfoDrawer}>
          Menu
        </Button>
      </div>
    );
  }

  render() {
    const { currentUserLoader: { data: { manager } } = { data: {} } } = this.context;

    return (
      <>
        <AppBar position="static" elevation={3} style={{ backgroundColor: grey[50] }}>
          <Toolbar>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <Typography variant="subtitle2" color="inherit" noWrap style={{ color: grey[900] }}>
                  <Link href="#" onClick={this.gotoHome} style={{ cursor: 'pointer' }}>
                    <img
                      src={process.env.PUBLIC_URL + '/dm_logo.png'}
                      alt=""
                      style={{ height: '52px', verticalAlign: 'middle', paddingRight: '2rem' }}
                    />
                  </Link>
                  D&amp;M Review Acknowledgement
                </Typography>

                <Drawer
                  anchor="right"
                  onClose={this.hideUserInfoDrawer}
                  open={this.state.userDrawerOpen}
                >
                  <List style={{ width: 250 }}>
                    <ListItem
                      button
                      key="home"
                      onClick={() => {
                        this.setState({ userDrawerOpen: false });
                        this.props.history.push(DASHBOARD);
                      }}
                    >
                      <ListItemIcon>
                        <HomeIcon />
                      </ListItemIcon>
                      <ListItemText primary="Home" />
                    </ListItem>
                  </List>

                  <Divider />

                  {/* <List>
                    <ListItem
                      button
                      key="Dashboard"
                      onClick={() => {
                        this.setState({ userDrawerOpen: false });
                        this.props.history.replace(DASHBOARD);
                      }}
                    >
                      <ListItemIcon>
                        <DashboardIcon />
                      </ListItemIcon>
                      <ListItemText primary="Dashboard" />
                    </ListItem>
                  </List> */}

                  {manager && (
                    <List>
                      <ListItem
                        button
                        key="Admin"
                        onClick={() => {
                          this.setState({ userDrawerOpen: false });
                          this.props.history.push(ADMIN_PAGE);
                        }}
                      >
                        <ListItemIcon>
                          <AdminIcon />
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                      </ListItem>
                    </List>
                  )}

                  <List>
                    <ListItem button key="logout" onClick={this.logoutUser}>
                      <ListItemIcon>
                        <ExitToAppIcon />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  </List>
                </Drawer>
              </Grid>
              <Grid item xs={4}>
                <div style={{ textAlign: 'right' }}>{this.getUserInfoButton()}</div>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </>
    );
  }
}

export default withRouter(Header);
