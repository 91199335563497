import React from 'react';

import { Delete, Email } from '@material-ui/icons';

export const getAdminPageColumns = (
  setRowData: any,
  setRowOpen: any,
  setRowDelete?: any,
  setSendEmails?: any
) => [
  {
    title: 'Division Code',
    field: 'divisionCode',
    width: '40px',
    grouping: false,
    render: (row: any) => {
      return <div style={{ color: 'darkblue' }}>{row?.divisionCode}</div>;
    },
  },
  {
    title: 'Division Name',
    field: 'divisionName',
    width: '170px',
    grouping: false,

    cellStyle: {
      maxWidth: '170px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  {
    title: 'Send Emails From',
    field: 'sendEmailsFrom',
    width: '170px',
    type: 'date',
  },
  {
    title: 'Send Emails To',
    field: 'sendEmailsTo',
    width: '170px',
    type: 'date',
  },
  {
    title: 'Completion Date',
    field: 'completionDate',
    width: '170px',
    type: 'date',
  },
  {
    title: 'Updated By',
    field: 'updatedBy',
    width: '170px',
    grouping: false,

    cellStyle: {
      maxWidth: '170px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  {
    title: 'Updated At',
    field: 'updatedAt',
    width: '200px',
    type: 'datetime',
  },
  {
    title: 'Send mails',
    width: '20px',
    grouping: false,
    disableClick: true,
    render: (row: any) => {
      return (
        <div
          onClick={(e) => {
            setSendEmails(row);
          }}
          style={{ cursor: 'pointer', opacity: '0.7' }}
        >
          <Email fontSize="small" />
        </div>
      );
    },
  },
  {
    title: 'Action',
    width: '20px',
    grouping: false,
    disableClick: true,
    render: (row: any) => {
      if (!row?.canUpdate) {
        // delete disabled
        return (
          <div style={{ opacity: '0.4' }}>
            <Delete fontSize="small" />
          </div>
        );
      }
      if (setRowDelete && row?.completionDate) {
        // delete alloved
        return (
          <div
            onClick={(e: any) => {
              setRowDelete(row);
            }}
            style={{ cursor: 'pointer', opacity: '0.7' }}
          >
            <Delete fontSize="small" />
          </div>
        );
      }
      // already deleted
      return (
        <div style={{ opacity: '0.3' }}>
          <Delete fontSize="small" />
        </div>
      );
    },
  },
];
