import React, { FC } from 'react';
import './index.css';
import { version } from '../../../package.json';

export const Footer: FC = () => {
  return (
    <div className="footer">
      <div>© 2021 DeGolyer and MacNaughton Version {version}.</div>
    </div>
  );
};
