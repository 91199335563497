//const ApiPrefix = "https://localhost:44397/api/";

import { API_PREFIX } from '../constants/config';

const ApiPrefix = API_PREFIX;

export type ResponseParseType = 'json' | 'stringify';
export type ReponseCallback = (invalid: boolean, data: any) => void;

function invokeJsonCall(
  api: string,
  method: string,
  token: string,
  onComplete: ReponseCallback,
  parseType: ResponseParseType = 'json'
) {
  let invalid = false;

  fetch(ApiPrefix + api, {
    method: method,

    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then((res) => {
      if (res.status >= 400) {
        invalid = true;
        return res.text();
      }
      if (parseType === 'stringify') {
        return JSON.stringify(res);
      }
      return res.json();
    })
    .then((data) => {
      onComplete(invalid, data);
    });
}

function invokeJsonCallWithBody(
  api: string,
  method: string,
  token: string,
  body: any,
  onComplete: ReponseCallback,
  parseType: ResponseParseType = 'json'
) {
  let invalid = false;

  fetch(ApiPrefix + api, {
    method: method,

    body: JSON.stringify(body),

    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then((res) => {
      if (res.status >= 400) {
        invalid = true;
        return res.text();
      }
      if (parseType === 'stringify') {
        return JSON.stringify(res);
      }
      return res.json();
    })
    .then((data) => {
      onComplete(invalid, data);
    });
}

export function ApiCallGetActiveEmployee(token: string, onComplete: ReponseCallback) {
  let api = 'employees/get';

  invokeJsonCall(api, 'GET', token, onComplete);
}

export function ApiCallSubmitAcknowledgement(
  token: string,
  data: any,
  onComplete: ReponseCallback
) {
  let api = 'acknowledgement';

  invokeJsonCallWithBody(api, 'POST', token, data, onComplete);
}

export function ApiCallGetAcknowledgedEmployees(token: string, onComplete: ReponseCallback) {
  let api = 'employees/listacknowledged';

  invokeJsonCall(api, 'GET', token, onComplete);
}

export function ApiCallGetEmailNotificationInfo(
  token: string,
  division: any,
  onComplete: ReponseCallback
) {
  let api = 'notification/get?division=' + encodeURIComponent(division);

  invokeJsonCall(api, 'GET', token, onComplete);
}

export function ApiCallUpsertEmailNotificationInfo(
  token: string,
  data: any,
  onComplete: ReponseCallback
) {
  let api = 'notification/upsert';

  invokeJsonCallWithBody(api, 'POST', token, data, onComplete);
}

export function ApiCallGetCompletionDates(token: string, onComplete: ReponseCallback) {
  let api = 'NotificationInfo/list';

  invokeJsonCall(api, 'GET', token, onComplete);
}

export function ApiCallSetCompletionDate(
  token: string,
  divisionCode: string,
  sendEmailsFrom: string,
  onComplete: ReponseCallback
) {
  let api = 'NotificationInfo/save';
  const body = { divisionCode, sendEmailsFrom };

  invokeJsonCallWithBody(api, 'POST', token, body, onComplete, 'stringify');
}

export function ApiCallDeleteCompletionDate(
  token: string,
  divisionCode: string,
  onComplete: ReponseCallback
) {
  let api = 'NotificationInfo/delete';
  const body = { divisionCode };

  invokeJsonCallWithBody(api, 'DELETE', token, body, onComplete, 'stringify');
}

export function ApiCallSendRemindersForDivision(
  token: string,
  divisionCode: string,
  onComplete: ReponseCallback
) {
  let api = 'SendMail/remindersfordivision?divisionCode=' + encodeURIComponent(divisionCode);

  invokeJsonCall(api, 'POST', token, onComplete, 'stringify');
}
