import { useEffect, useState, useCallback } from 'react';
import {
  ApiCallDeleteCompletionDate,
  ApiCallGetCompletionDates,
  ApiCallSendRemindersForDivision,
  ApiCallSetCompletionDate,
} from 'Utils/ApiCalls';

export interface IUseAdminPageDataProps {
  authToken: string;
}
export const useAdminPageData = ({ authToken }: IUseAdminPageDataProps) => {
  const [completionDates, setCompletionDates] = useState([]);
  const [loading, setLoading] = useState(false);

  const GetCompletionDatesResponse = (invalid: any, data: any) => {
    setLoading(false);
    setCompletionDates(data);
  };

  const Load = useCallback(() => {
    setLoading(true);
    ApiCallGetCompletionDates(authToken, GetCompletionDatesResponse);
  }, [authToken]);

  useEffect(() => {
    Load();
  }, [Load]);

  const Save = useCallback(
    ({ divisionCode, sendEmailsFrom, onComplete }) => {
      setLoading(true);
      ApiCallSetCompletionDate(authToken, divisionCode, sendEmailsFrom, (invalid, data) => {
        setLoading(false);
        onComplete && onComplete(invalid, data);
      });
    },
    [authToken]
  );

  const Delete = useCallback(
    ({ divisionCode, onComplete }) => {
      setLoading(true);
      ApiCallDeleteCompletionDate(authToken, divisionCode, (invalid, data) => {
        setLoading(false);
        onComplete && onComplete(invalid, data);
      });
    },
    [authToken]
  );

  const SendRemindersForDivision = useCallback(
    ({ divisionCode, onComplete }) => {
      setLoading(true);
      ApiCallSendRemindersForDivision(authToken, divisionCode, (invalid, data) => {
        setLoading(false);
        onComplete && onComplete(invalid, data);
      });
    },
    [authToken]
  );

  return {
    loading,
    completionDates,
    Load,
    Save,
    Delete,
    SendRemindersForDivision,
  };
};
