import { useCurentUser } from 'Components/AdminPage/curentUserHook';
import React, { createContext, FC, useContext } from 'react';

export interface IContextState {
  currentUserLoader?: any;
}

export interface IContextActions {}

const initialState: IContextState = {};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface IProviderProps {
  authToken: string;
}

export const Provider: FC<IProviderProps> = ({ authToken, children }) => {
  const currentUserLoader = useCurentUser({ authToken });
  return (
    <ComponentContext.Provider value={{ currentUserLoader }}>{children}</ComponentContext.Provider>
  );
};

export const useComponentContext = () => useContext(ComponentContext);

export default ComponentContext;
