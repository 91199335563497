import { createMuiTheme, CssBaseline, MuiThemeProvider } from '@material-ui/core';
import React, { useState } from 'react';

const createTheme = (): any => {
  return createMuiTheme(
    // { "palette": { "common": { "black": "#000", "white": "#fff" }, "background": { "paper": "rgba(247, 244, 231, 1)", "default": "rgba(247, 240, 210, 1)" }, "primary": { "light": "rgba(230, 214, 159, 1)", "main": "rgba(184, 160, 85, 1)", "dark": "rgba(123, 108, 59, 1)", "contrastText": "#fff" }, "secondary": { "light": "rgba(29, 160, 79, 1)", "main": "rgba(0, 98, 37, 1)", "dark": "rgba(0, 61, 23, 1)", "contrastText": "#fff" }, "error": { "light": "#e57373", "main": "#f44336", "dark": "#d32f2f", "contrastText": "#fff" }, "text": { "primary": "rgba(0, 0, 0, 0.87)", "secondary": "rgba(0, 0, 0, 0.54)", "disabled": "rgba(0, 0, 0, 0.38)", "hint": "rgba(0, 0, 0, 0.38)" } } }
    {
      palette: {
        common: { black: '#000', white: '#fff' },
        background: { paper: '#fff', default: 'rgba(248, 244, 225, 1)' },
        primary: { light: '#7986cb', main: '#3f51b5', dark: '#303f9f', contrastText: '#fff' },
        secondary: { light: '#ff4081', main: '#f50057', dark: '#c51162', contrastText: '#fff' },
        error: { light: '#e57373', main: '#f44336', dark: '#d32f2f', contrastText: '#fff' },
        text: {
          primary: 'rgba(0, 0, 0, 0.87)',
          secondary: 'rgba(0, 0, 0, 0.54)',
          disabled: 'rgba(0, 0, 0, 0.38)',
          hint: 'rgba(0, 0, 0, 0.38)',
        },
      },
    }
  );
};

export const Theme = React.forwardRef(function Theme(props: any, ref: any) {
  const [theme] = useState(createTheme());
  const { component: Component = 'div', ...other } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Component ref={ref} {...other} />
    </MuiThemeProvider>
  );
});
