import React, { useState, useEffect } from 'react';
import {
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import LoadingOverlay from 'react-loading-overlay';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import EasyAutoComplete from '../Misc/EasyAutoComplete';
import AlertDialog from '../Misc/AlertDialog';

import {
  ApiCallGetEmailNotificationInfo,
  ApiCallUpsertEmailNotificationInfo,
} from '../../Utils/ApiCalls';

const EmpAckCompletionSetDate = (props) => {
  const [open, setOpen] = useState(false);
  const [divisions, setDivisions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [data, setData] = useState({ completionDate: null, days: 3 });
  const [loading, setLoading] = useState(false);

  const [alertTitle, setAlertTitle] = useState('Info');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);

  const setD = (attr, val) => {
    var d = data;
    d[attr] = val;

    setData({ ...data, d });
  };

  const closeForm = () => {
    setOpen(false);

    props.closeHandler();
  };

  const saveChanges = () => {
    let d = new Date(data.completionDate);
    d.setHours(22, 30);

    let upsertData = {
      completionDate: d,
      sendEmailsFrom: data.days,
      division: selectedDivision.code,
    };

    setLoading(true);
    ApiCallUpsertEmailNotificationInfo(
      props.authToken,
      upsertData,
      UpsertEmailNotificationInfoResponse
    );
  };

  useEffect(() => {
    setOpen(props.open);

    if (props.open && selectedDivision) {
      setLoading(true);
      ApiCallGetEmailNotificationInfo(
        props.authToken,
        selectedDivision.code,
        GetEmailNotificationInfoResponse
      );
    }
  }, [props.open, selectedDivision, props.authToken]);

  useEffect(() => {
    setDivisions(props.divisions);

    if (props.divisions.length > 0) setSelectedDivision(props.divisions[0]);
  }, [props.divisions]);

  const GetEmailNotificationInfoResponse = (invalid, data) => {
    setLoading(false);

    var d = data;
    d.completionDate = data.completionDate;
    d.days = data.days;

    setData(d);
  };

  const UpsertEmailNotificationInfoResponse = (invalid, data) => {
    setLoading(false);

    setAlertTitle('Info');
    setAlertMessage('Completion Date Saved.');
    setAlertOpen(true);

    console.log('upsert response', data);
  };

  return (
    <Dialog maxWidth="sm" fullWidth={true} open={open}>
      <DialogTitle>Set Completion Date</DialogTitle>

      <AlertDialog
        title={alertTitle}
        message={alertMessage}
        open={alertOpen}
        closeHandler={() => {
          setAlertOpen(false);
        }}
      />

      <DialogContent dividers>
        <LoadingOverlay active={loading} spinner text="Please wait...">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <EasyAutoComplete
                  items={divisions}
                  selected={selectedDivision}
                  name="Divisions"
                  label="Division"
                  optionsLabel="code"
                  optionsLabel2="name"
                  selectedOptionLabel={(option) => option.name}
                  selectedChanged={(value) => {
                    setSelectedDivision(value);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  label="Completion Date for Acknowledgements"
                  value={data.completionDate}
                  autoOk
                  fullWidth
                  onChange={(date) => {
                    let d = date;

                    setD('completionDate', d);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Days prior to the completion date before warning e-mails"
                  fullWidth
                  value={data.days}
                  inputProps={{ step: '1', min: '0', max: '20' }}
                  type="number"
                  onChange={(e) => {
                    setD('days', parseInt(e.target.value));
                  }}
                />
              </Grid>

              <Grid item xs={12} container>
                <Grid item xs={4}></Grid>

                <Grid item xs={4}>
                  <Button fullWidth variant="contained" color="secondary" onClick={saveChanges}>
                    Save Changes
                  </Button>
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </LoadingOverlay>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={closeForm}>
          Close Form
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmpAckCompletionSetDate;
