import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { Box, Paper } from '@material-ui/core';

class AppLogin extends Component {
  constructor(props) {
    super(props);

    this.btnLoginClick = this.btnLoginClick.bind(this);
  }

  btnLoginClick() {
    this.props.loginHandler();
  }

  render() {
    return (
      <Container component="main" maxWidth="xs">
        <Paper style={{ padding: '1rem', marginTop: '3rem' }} elevation={6}>
          <form>
            <Box m="3rem" />

            <Button variant="contained" fullWidth color="primary" onClick={this.btnLoginClick}>
              Login to the Application
            </Button>

            <Box m="3rem" />
          </form>
        </Paper>
      </Container>
    );
  }
}

export default AppLogin;
