import { Button, Grid, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { FC, useCallback, useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { ReponseCallback } from 'Utils/ApiCalls';

export interface ICompletionDateForm {
  divisionCode: string;
  divisionName: string;
  emailsFromDate?: string;
  refresh: () => void;
  onClose: () => void;
  onSave: ({
    divisionCode,
    sendEmailsFrom,
    onComplete,
  }: {
    divisionCode: string;
    sendEmailsFrom: string;
    onComplete?: ReponseCallback;
  }) => void;
}

export const CompletionDateForm: FC<ICompletionDateForm> = ({
  divisionCode,
  divisionName,
  emailsFromDate,
  onClose,
  onSave,
  refresh,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [sendEmailsFrom, setSendEmailsFrom] = useState<any>();

  useEffect(() => {
    setSendEmailsFrom(emailsFromDate);
  }, [emailsFromDate]);

  const saveChanges = useCallback(() => {
    setErrorMessage(() => undefined);
    onSave({
      divisionCode,
      sendEmailsFrom,
      onComplete: (invalid) => {
        if (!invalid) {
          refresh();
          onClose();
        } else {
          setErrorMessage(() => 'Error, please try again...');
        }
      },
    });
  }, [onClose, onSave, divisionCode, sendEmailsFrom, refresh]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Division"
            fullWidth
            value={divisionName || ''}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <KeyboardDatePicker
            required={true}
            disableToolbar
            variant="inline"
            // format="MM/dd/yyyy p"
            format="MM/dd/yyyy"
            margin="normal"
            label="Send Emails From"
            value={sendEmailsFrom}
            autoOk
            fullWidth
            onChange={(date: Date | null) => {
              // date && console.log('Parsed date', date, date.toISOString());
              setSendEmailsFrom(date);
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            helperText={sendEmailsFrom ? 'Date is required' : ''}
          />
        </Grid>

        <Grid item xs={12} container>
          <Grid item xs={4}></Grid>

          <Grid item xs={4}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={saveChanges}
              disabled={!sendEmailsFrom}
            >
              Save Changes
            </Button>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
        {errorMessage ? (
          <Grid item xs={12} container>
            <div style={{ color: 'red' }}>{errorMessage}</div>
          </Grid>
        ) : undefined}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
