import { useCallback, useEffect, useState } from 'react';
import { ApiCallGetActiveEmployee } from 'Utils/ApiCalls';

export interface IUseCurentUserProps {
  authToken: string;
}
export const useCurentUser = ({ authToken }: IUseCurentUserProps) => {
  const [loading, setLoading] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Info');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);

  const [data, setData] = useState({
    empNo: '',
    empName: '',
    signature: '',
    date: '',
    comments: '',
    submitEnabled: false,
    manager: false,
    executive: false,
    confirmedBeforeSubmitting: false,
    managerDivisions: [],
    hasPendingAcknowledgement: false,
  });

  const getActiveEmployeeResponse = useCallback((invalid, responseData) => {
    setLoading(false);

    if (invalid) {
      setData((prevData) => {
        let d = prevData;
        d.submitEnabled = false;
        return { ...prevData, d };
      });

      setAlertTitle('Error');
      setAlertMessage('Sorry, you are not authorized to use this form.');
      setAlertOpen(true);
    } else {
      setData((prevData) => {
        let d = prevData;
        d.empNo = responseData.code;
        d.empName = responseData.name;
        d.signature = responseData.email;
        d.date = responseData.date;
        d.comments = responseData.comments;
        d.submitEnabled = responseData.submitEnabled || responseData.hasPendingAcknowledgement;
        d.manager = responseData.manager;
        d.executive = responseData.executive;
        d.managerDivisions = responseData.managerDivisions;
        d.hasPendingAcknowledgement = responseData.hasPendingAcknowledgement;
        return { ...prevData, d };
      });
    }
  }, []);

  useEffect(() => {
    ApiCallGetActiveEmployee(authToken, getActiveEmployeeResponse);
    setLoading(true);
  }, [authToken, getActiveEmployeeResponse]);

  return { loading, alertTitle, alertMessage, alertOpen, setAlertOpen, data };
};
