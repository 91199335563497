// #region React Core

import React, { FC, useState, useEffect } from 'react';

// #endregion

// #region Material-UI Components

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Typography,
  Box,
  Container,
  Grid,
  TextareaAutosize,
} from '@material-ui/core';

import { uniqueId } from 'lodash';

// #endregion

// #region Component definition interface

import { ConfirmationDialogProps, Dialog as IDialog } from './interfaces';

// #endregion

//
// Displays generic Confirmation Dialog with title and message
// with handler when Ok or Cancel button is clicked
//
const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  confirmButtonProps,
  reverseColors,
  ...props
}) => {
  // #region State

  const [dialogOpen, setOpen] = useState(false);

  // #endregion

  // #region Effects

  useEffect((): void => {
    setOpen(open);
  }, [open]);

  // #endregion

  // #region Form Methods

  const closeHandler = (cancel: boolean): void => {
    setOpen(false);
    onClose(cancel);
  };

  // #endregion

  const setDialogContent = (dialogProps: IDialog): JSX.Element => (
    <div key={dialogProps.title || uniqueId()}>
      <DialogTitle>
        <Typography>
          <span style={{ fontWeight: 800, fontSize: '0.9rem' }}>
            <div dangerouslySetInnerHTML={{ __html: dialogProps?.title ?? 'Confirmation' }} />
          </span>
        </Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText style={{ color: '#444', fontSize: '0.9rem', ...dialogProps?.style }}>
          <div dangerouslySetInnerHTML={{ __html: dialogProps?.message ?? '' }} />
          {!!dialogProps?.setComment ? (
            <>
              <Box marginTop={1}></Box>
              <TextareaAutosize
                color="grey"
                style={{
                  width: '95%',
                  minHeight: '1rem',
                  padding: 10,
                  borderRadius: 4,
                  overflow: 'auto',
                  border: '1px #ccc solid',
                }}
                aria-label="minimum height"
                rowsMin={4}
                placeholder="Write comment (optional)..."
                onChange={(event: React.ChangeEvent<{ value: string }>) => {
                  dialogProps.setComment!(event.target.value);
                }}
                value={dialogProps?.comment}
                className="MuiInputBase-input"
              />
            </>
          ) : undefined}
        </DialogContentText>
      </DialogContent>
    </div>
  );

  return (
    <Dialog maxWidth="sm" open={dialogOpen}>
      <Container>
        {props?.dialogs
          ? props?.dialogs.map(setDialogContent)
          : setDialogContent({
              title: props?.title,
              message: props?.message ?? '',
              comment: props?.comment,
              setComment: props?.setComment,
              style: props?.style,
            })}

        <Box marginBottom={3} />
        <DialogActions>
          <Grid style={{ margin: 'auto' }}>
            <Button
              color={!reverseColors ? 'primary' : 'secondary'}
              variant="contained"
              size="medium"
              onClick={(): void => {
                closeHandler(true);
              }}
              {...confirmButtonProps}
              style={{ fontSize: '0.85rem', ...confirmButtonProps?.style }}
            >
              Confirm
            </Button>
            &nbsp; &nbsp;
            <Button
              color={!reverseColors ? 'secondary' : 'primary'}
              variant="contained"
              size="medium"
              onClick={(): void => {
                closeHandler(false);
              }}
              style={{ fontSize: '0.85rem' }}
            >
              Back
            </Button>
          </Grid>
        </DialogActions>
        <Box marginBottom={2} />
      </Container>
    </Dialog>
  );
};

export default ConfirmationDialog;
