import React, { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

export interface ICompletionDateDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
}

export const CompletionDateDialog: FC<ICompletionDateDialogProps> = ({
  open,
  onClose,
  title,
  children,
}) => {
  return (
    <Dialog maxWidth="sm" fullWidth={true} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Close Form
        </Button>
      </DialogActions>
    </Dialog>
  );
};
