import { Paper, Container, Box, Typography } from '@material-ui/core';
import LoadingOverlay from 'react-loading-overlay-ts';
import React, { FC, useEffect, useState } from 'react';
import MaterialTable, { MTableBodyRow } from 'material-table';
import { useWindowSize } from 'Utils/windowSizeHook';
import { getAdminPageColumns } from './Columns';
import { useAdminPageData } from './hook';
import EmpAckCompletionSetDate from 'Components/HomePage/EmpAckCompletionSetDate';
import { useComponentContext as useCurrentUserContext } from 'context/UserContext/UserContext';
import AlertDialog from 'Components/Misc/AlertDialog';
import { CompletionDateDialog } from './components/CompletionDateDialog/CompletionDateDialog';
import { CompletionDateForm } from './components/CompletionDateForm/CompletionDateForm';
import ConfirmationDialog from 'Components/ConfirmationDialog';
import s from './style.module.css';
export interface IAdminPageProps {
  authToken: string;
}

export const AdminPage: FC<IAdminPageProps> = ({ authToken }) => {
  const [, screenHeight] = useWindowSize();
  const [empAckSetDateOpen, setEmpAckSetDateOpen] = useState(false);
  const [completionDateDialogData, setCompletionDateDialogData] = useState<any | undefined>();
  const [sendEmailsData, setSendEmailsData] = useState<any | undefined>();
  const [rowDeleteData, setRowDeleteData] = useState<any | undefined>();
  const [errorMessage, setErrorMessage] = useState<string>();
  // const [hoveringOver, setHoveringOver] = useState('');

  const {
    loading,
    completionDates: adminPageData,
    Load: LoadCompletionDates,
    Save: SaveCompletionDate,
    Delete: DeleteCompletionDate,
    SendRemindersForDivision,
  } = useAdminPageData({ authToken });

  const {
    currentUserLoader: {
      data: { manager, managerDivisions },
      alertMessage,
      alertOpen,
      alertTitle,
      loading: currentUserLoading,
      setAlertOpen,
    } = { data: {} },
  } = useCurrentUserContext();

  const [adminPageColumns, setAdminPageColumns] = useState<any[]>([]);

  const setAdminPageInfoData = (x: any) => {
    setCompletionDateDialogData(x);
  };

  const setAdminPageInfoOpen = (open: boolean) => {};

  const completionDateDialogClose = () => {
    setCompletionDateDialogData(undefined);
  };

  const setSendEmails = (row: any) => {
    setSendEmailsData(row);
  };

  const sendEmails = (confirm: boolean) => {
    setSendEmailsData(() => undefined);
    if (!confirm) {
      return;
    }

    const { divisionCode } = sendEmailsData;
    SendRemindersForDivision({
      divisionCode,
      onComplete: (invalid: boolean) => {
        if (invalid) {
          setErrorMessage(() => 'Error, please try again...');
        }
      },
    });
  };

  const deleteRow = (confirm: boolean) => {
    setRowDeleteData(() => undefined);
    if (!confirm) {
      return;
    }

    const { divisionCode } = rowDeleteData;
    DeleteCompletionDate({
      divisionCode,
      onComplete: (invalid: boolean) => {
        if (!invalid) {
          LoadCompletionDates();
        } else {
          setErrorMessage(() => 'Error, please try again...');
        }
      },
    });
  };

  const setRowDelete = (row: any) => {
    setRowDeleteData(row);
  };

  useEffect(() => {
    const columns = getAdminPageColumns(
      setAdminPageInfoData,
      setAdminPageInfoOpen,
      setRowDelete,
      setSendEmails
    );
    setAdminPageColumns(columns);
  }, []);

  let alternateRow = true;

  // const handleRowHover = useCallback((propsData: any) => {
  //   setHoveringOver(() => propsData.data.divisionCode);
  // }, []);

  // const handleRowHoverLeave = useCallback(() => setHoveringOver(() => ''), []);

  return (
    <Container maxWidth={'xl'}>
      <ConfirmationDialog
        title="Send reminder emails"
        message={
          'Reminder emails will be sent to employees of division ' +
          sendEmailsData?.divisionName +
          ' (' +
          sendEmailsData?.divisionCode +
          ') if sending is enabled for today. Are you sure?'
        }
        open={!!sendEmailsData}
        onClose={sendEmails}
        reverseColors
      />
      <ConfirmationDialog
        title="Delete dates"
        message={
          'Division ' +
          rowDeleteData?.divisionName +
          ' (' +
          rowDeleteData?.divisionCode +
          ') dates will be deleted. Please confirm ...'
        }
        open={!!rowDeleteData}
        onClose={deleteRow}
        reverseColors
      />
      <AlertDialog
        title="Error"
        message={errorMessage}
        open={!!errorMessage}
        onClose={() => {
          setErrorMessage(() => undefined);
        }}
      />
      <CompletionDateDialog
        open={!!completionDateDialogData}
        onClose={completionDateDialogClose}
        title="Update Completion Date"
      >
        <CompletionDateForm
          divisionCode={completionDateDialogData?.divisionCode}
          divisionName={completionDateDialogData?.divisionName}
          emailsFromDate={completionDateDialogData?.sendEmailsFrom}
          refresh={() => {
            LoadCompletionDates();
          }}
          onClose={completionDateDialogClose}
          onSave={SaveCompletionDate}
        ></CompletionDateForm>
      </CompletionDateDialog>

      <AlertDialog
        title={alertTitle}
        message={alertMessage}
        open={alertOpen}
        closeHandler={() => {
          setAlertOpen(false);
        }}
      />
      <LoadingOverlay active={loading || currentUserLoading} spinner text="Please wait...">
        {manager ? (
          <Paper elevation={3}>
            <EmpAckCompletionSetDate
              divisions={managerDivisions}
              authToken={authToken}
              open={empAckSetDateOpen}
              closeHandler={() => {
                setEmpAckSetDateOpen(false);
              }}
            />
            <Box style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingTop: 'auto' }}>
              <Box p={1}></Box>
              <Box m={1}></Box>
              <Typography variant="body2" className="page-title">
                Email Management
              </Typography>
            </Box>
            <Box p={0} style={{ textAlign: 'center' }}>
              <Box p={1}></Box>
              <Box m={1}></Box>
              <MaterialTable
                columns={adminPageColumns}
                data={adminPageData}
                onRowClick={(evt, selectedRow: any) => {
                  if (selectedRow?.canUpdate) {
                    setAdminPageInfoOpen(true);
                    setAdminPageInfoData(selectedRow);
                  }
                }}
                options={{
                  filtering: true,
                  toolbar: false,
                  //draggable: false, (must use for grouping to be enabled)
                  grouping: true,

                  headerStyle: { position: 'sticky', top: 0 },
                  maxBodyHeight: screenHeight - 350,
                  minBodyHeight: screenHeight - 350,
                  emptyRowsWhenPaging: false,
                  pageSize: 20,
                  pageSizeOptions: [10, 20, 30],
                  tableLayout: 'auto',
                  loadingType: 'linear',
                  debounceInterval: 450,
                  padding: 'dense',
                  rowStyle: (rowData) => {
                    alternateRow = !alternateRow;
                    // rowData.divisionCode === hoveringOver
                    return {
                      backgroundColor: !alternateRow ? '#F6F6F6' : '#FCFCFC',
                      opacity: rowData?.canUpdate ? undefined : '0.7',
                    };
                  },
                }}
                components={{
                  Row: (props) => {
                    return (
                      <MTableBodyRow
                        {...props}
                        className={props.data.canUpdate ? s.rowEnabled : s.rowDisabled}
                        // onMouseEnter={() => handleRowHover(props)}
                        // onMouseLeave={() => handleRowHoverLeave()}
                      />
                    );
                  },
                }}
              ></MaterialTable>
            </Box>
          </Paper>
        ) : undefined}
      </LoadingOverlay>
    </Container>
  );
};
