import React, { useState, useEffect } from 'react';
import {
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';

const EmployeeInfo = (props) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  const closeForm = () => {
    setOpen(false);

    props.closeHandler();
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <Dialog maxWidth="md" fullWidth={true} open={open}>
      <DialogTitle>Employee Acknowledgment</DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TextField
              label="Employee No."
              fullWidth
              value={data.code}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={7}>
            <TextField
              label="Employee Name"
              fullWidth
              value={data.name}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={2}></Grid>

          <Grid item xs={3}>
            <TextField
              label="E-mail"
              fullWidth
              value={data.email}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              label="Date"
              fullWidth
              value={data.date}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <TextField
              label="Employee Comments"
              multiline
              rows={6}
              fullWidth
              value={data.comments}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Manager Comments"
              multiline
              rows={6}
              fullWidth
              value={data.managerComments}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={closeForm}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmployeeInfo;
